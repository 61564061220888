import { IVehicleTypeModel } from '.';
import { ISparePartModel } from './SparePartsModel';

export interface IDashboardModel {
  dashboard: IDashboardDataModel;
  isLoading: boolean;
  dashboardFilter: IDashboardFilterModel;
  vehicleTypes: IVehicleTypeModel[];
  spareParts: ISparePartModel[];
}

export interface IDashboardDataModel {
  vehiclesCount: IVehicleCountModel[];
  servicesCountPerInterval: IServicesCountPerIntervalModel[];
  sparePartsUsed: ISparePartsUsedModel[];
  vehiclesHeldInWarehouse: IVehicleCountModel[];
  ordinaryServiceDuration: IVehicleCountModel[];
}

export interface IVehicleCountModel {
  vehicleTypeId: string;
  count: number;
}

export const getTotalVehicleTypesCount = (items: IVehicleCountModel[]): number => {
  return items.reduce((accumulator, item) => accumulator + item.count, 0);
};

export interface IServicesCountPerIntervalModel {
  interval: string;
  data: IVehicleCountModel[];
}

export interface ISparePartsUsedModel {
  sparePartId: string;
  count: number;
}

export interface IDashboardFilterModel {
  period: IPeriodModel;
  vehicleTypeId: string;
}

export enum IPeriodModel {
  LAST_WEEK = 'week',
  LAST_MONTH = 'month',
  LAST_YEAR = 'year',
}

import * as React from 'react';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { Grid } from '@material-ui/core';
import { DashboardWidget } from 'app/components/DashboardWidget/DashboardWidget';
import { NumberOfServices } from 'app/components/NumberOfServices/NumberOfServices';
import { SparePartsUsage } from 'app/components/SparePartsUsage/SparePartsUsage';
import {
  ServeedoSelectButton,
  ISelectButtonOption,
  ISelectButtonStyle,
} from 'app/components/common';

import gradients from 'app/helpers/colors';

import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import AvTimerOutlinedIcon from '@material-ui/icons/AvTimerOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/CommuteOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CommuteOutlinedIcon from '@material-ui/icons/CommuteOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import {
  IDashboardModel,
  IDashboardFilterModel,
  IPeriodModel,
  ISupportedRoutes,
  IRegion,
} from 'app/models';
import { RouteComponentProps } from 'react-router';
import { getTotalVehicleTypesCount } from '../../models/DashboardModel';

export interface IProps extends RouteComponentProps<any> {
  dashboard: IDashboardModel;
  fetchVehicleTypes: () => void;
  fetchSpareParts: () => void;
  fetchDashboardData: () => void;
  updateDashboardFilter: (filter: IDashboardFilterModel) => void;
  selectedRegion: IRegion | null;
}

export interface IState {
  selectedVehicleTypeIndex: number;
  isLoading: boolean;
}

class DashBoard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedVehicleTypeIndex: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.props.fetchVehicleTypes();
    this.props.fetchSpareParts();
    this.props.fetchDashboardData();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      dashboard: { dashboardFilter },
      selectedRegion,
    } = this.props;
    if (
      prevProps.dashboard.dashboardFilter !== dashboardFilter ||
      prevProps.selectedRegion !== selectedRegion
    ) {
      this.props.fetchDashboardData();
    }

    if (prevProps.selectedRegion !== selectedRegion && selectedRegion) {
      let newUrl =
        `${selectedRegion.key !== 'all' ? '/' + selectedRegion.key : ''}` +
        ISupportedRoutes.DASHBOARD;
      this.props.history.push(newUrl);
    }
  }

  changeTimePeriod = (option: ISelectButtonOption) => {
    const {
      dashboard: { dashboardFilter },
      updateDashboardFilter,
    } = this.props;
    this.setState({
      isLoading: true,
    });
    updateDashboardFilter({
      ...dashboardFilter,
      period: option.value as IPeriodModel,
    });
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 800);
  };

  renderRightPanel = (): JSX.Element => {
    const {
      dashboard: {
        dashboardFilter: { period },
      },
    } = this.props;
    const options: ISelectButtonOption[] = [
      {
        label: 'All',
        value: 'all',
      },
    ];
    this.props.dashboard.vehicleTypes.forEach((type) => {
      options.push({
        label: type.name,
        value: type.id,
      });
    });
    const options2: ISelectButtonOption[] = [
      {
        label: 'Last Week',
        value: IPeriodModel.LAST_WEEK,
      },
      {
        label: 'Last Month',
        value: IPeriodModel.LAST_MONTH,
      },
      {
        label: 'Last Year',
        value: IPeriodModel.LAST_YEAR,
      },
    ];
    return (
      <div style={{ display: 'flex' }}>
        <ServeedoSelectButton
          options={options2}
          onSelect={this.changeTimePeriod}
          selectedOption={options2.find((i) => i.value === period)!}
          buttonStyle={ISelectButtonStyle.SUCCESS}
          leftIcon={<CalendarTodayOutlinedIcon />}
        />{' '}
        <ServeedoSelectButton
          options={options}
          onSelect={(option) => {
            const selectedVehicleTypeIndex = options.indexOf(option);
            this.setState({ selectedVehicleTypeIndex });
          }}
          selectedOption={options[this.state.selectedVehicleTypeIndex]}
          buttonStyle={ISelectButtonStyle.DEFAULT}
          leftIcon={<CommuteOutlinedIcon />}
        />
      </div>
    );
  };

  render(): JSX.Element {
    const {
      dashboard: { dashboard, vehicleTypes, spareParts },
      selectedRegion,
    } = this.props;

    const { isLoading, selectedVehicleTypeIndex } = this.state;

    const selectedVehicleId =
      selectedVehicleTypeIndex === 0 ? null : vehicleTypes[selectedVehicleTypeIndex - 1].id;

    let fleetSize = 0;
    if (selectedVehicleId) {
      const type = dashboard.vehiclesCount.find((item) => item.vehicleTypeId === selectedVehicleId);
      if (type) {
        fleetSize = type.count;
      }
    } else {
      fleetSize = getTotalVehicleTypesCount(
        dashboard.vehiclesCount.filter((item) => {
          if (vehicleTypes) {
            return vehicleTypes.find((type) => type.id === item.vehicleTypeId) !== undefined;
          }
          return true;
        }),
      );
    }

    let avgServicesPerDay: number | undefined;
    if (dashboard.servicesCountPerInterval.length) {
      avgServicesPerDay = dashboard.servicesCountPerInterval.reduce((accumulator, item) => {
        if (selectedVehicleId) {
          const unit = item.data.find((u) => u.vehicleTypeId === selectedVehicleId);
          return accumulator + (unit ? unit.count : 0);
        } else {
          return accumulator + getTotalVehicleTypesCount(item.data);
        }
      }, 0);
      avgServicesPerDay /= dashboard.servicesCountPerInterval.length;
    }

    let vehiclesOnHold: number | undefined;
    if (dashboard.vehiclesHeldInWarehouse.length) {
      vehiclesOnHold = dashboard.vehiclesHeldInWarehouse.reduce((accumulator, item) => {
        if (selectedVehicleId) {
          return accumulator + (item.vehicleTypeId === selectedVehicleId ? item.count : 0);
        } else {
          return item.count;
        }
      }, 0);
    }

    let avgServiceDuration: number | undefined | string;
    if (dashboard.ordinaryServiceDuration.length) {
      if (selectedVehicleId) {
        const data = dashboard.ordinaryServiceDuration.find(
          (item) => item.vehicleTypeId === selectedVehicleId,
        );
        if (data) {
          avgServiceDuration = data.count / 60;
        }
      } else {
        avgServiceDuration = dashboard.ordinaryServiceDuration.reduce((accumulator, item) => {
          return accumulator + item.count;
        }, 0);
        avgServiceDuration /= dashboard.ordinaryServiceDuration.length * 60; // convert seconds to minutes
      }
    }
    if (avgServiceDuration !== undefined) {
      if (avgServiceDuration < 1) {
        // show seconds
        avgServiceDuration = `${Math.round((avgServiceDuration as number) * 60)} secs.`;
      } else {
        // round to 2 decimals;
        avgServiceDuration = `${Math.round((avgServiceDuration as number) * 100) * 0.01} mins.`;
      }
    } else {
      avgServiceDuration = 'n/a';
    }

    return (
      <div className={'dashboard-container'}>
        <WidgetHeader
          headerText={'Overview'}
          headerSmallText={selectedRegion ? selectedRegion.name.toUpperCase() : 'ALL'}
          rightPanel={this.renderRightPanel()}
        />
        <Grid container spacing={3} style={{ marginTop: 24 }}>
          <Grid item lg={3} sm={6} xs={12}>
            <DashboardWidget
              title="avg. services per day"
              value={
                avgServicesPerDay !== undefined
                  ? Math.round(avgServicesPerDay! * 100) * 0.01
                  : 'n/a'
              }
              icon={BuildOutlinedIcon}
              image={gradients.blue}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <DashboardWidget
              title="Avg. duration of ordinary service"
              value={avgServiceDuration}
              icon={AvTimerOutlinedIcon}
              image={gradients.orange}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <DashboardWidget
              title="Fleet Size"
              value={fleetSize}
              icon={PeopleAltOutlinedIcon}
              image={gradients.indigo}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <DashboardWidget
              title="Vehicles hold more than 24h"
              value={vehiclesOnHold !== undefined ? vehiclesOnHold : 'n/a'}
              icon={ErrorOutlineOutlinedIcon}
              image={gradients.red}
            />
          </Grid>
          <Grid item lg={9} xs={12}>
            <NumberOfServices
              data={dashboard.servicesCountPerInterval}
              selectedVehicleId={selectedVehicleId}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <SparePartsUsage
              vehicleTypes={vehicleTypes}
              spareParts={spareParts}
              items={dashboard.sparePartsUsed}
              isLoading={isLoading}
              selectedVehicleId={selectedVehicleId}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default DashBoard;

import { IResponseHandlerModel } from 'app/models';

/**
 * SuccessHandlerHelper Class - For managing successful responses.
 */
export class SuccessHandlerHelper {
  constructor(response: any, status: number) {
    this.responseModel.code = status;
    this.responseModel.data = status === 204 ? '' : response;
  }

  public responseModel: IResponseHandlerModel = {
    code: 0,
    isError: false,
    timestamp: Date.now(),
  };
}

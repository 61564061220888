/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {string} property Key of the object to sort.
 */
export const dynamicSort = (property: string) => {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return (a: any, b: any) => {
    if (sortOrder === -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
};

/**
 * Table Header Sorting
 */

export const desc = (a: any, b: any, orderBy: any) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getSorting = (order: any, orderBy: any) => {
  return order === 'desc'
    ? (a: any, b: any) => desc(a, b, orderBy)
    : (a: any, b: any) => -desc(a, b, orderBy);
};

export const stableSort = <T>(array: T[], cmp: any) => {
  const stabilizedThis = array.map((el: T, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
};

export const togglePresenceItem = (items: any[], item: string): any[] => {
  const index = items.indexOf(item);
  if (index === -1) {
    // add item
    return items.concat([item]);
  } else {
    // remove item
    const result = items.concat([]);
    result.splice(index, 1);
    return result;
  }
};

export const capitalizeFirstLetter = (text: string): string => {
  text = text.split('-').join(' ');
  return text.charAt(0).toUpperCase() + text.slice(1);
};

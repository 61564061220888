import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IHotspotsActionsType } from 'app/actions';
import { IHotspotsModel, IHotspotModel } from 'app/models';

const initialState: IHotspotsModel = {
  hotspotsList: [],
  selectedHotspot: null,
  isLoading: false,
  isEditing: false,
};

// tslint:disable-next-line:typedef
export const hotspotsReducer: Reducer<IRootState.HotspotsState, IHotspotsModel> = handleActions<
  IRootState.HotspotsState,
  IHotspotsModel | IHotspotModel | IHotspotModel[]
>(
  {
    [IHotspotsActionsType.REQUEST_HOT_SPOTS]: (state = initialState): IRootState.HotspotsState => ({
      ...state,
      isLoading: true,
      isEditing: false,
      selectedHotspot: null,
      hotspotsList: []
    }),
    [IHotspotsActionsType.RECEIVE_HOT_SPOTS]: (
      state = initialState,
      action,
    ): IRootState.HotspotsState => ({
      ...state,
      isLoading: false,
      hotspotsList: action.payload as IHotspotModel[],
    }),
    [IHotspotsActionsType.FAILURE_HOT_SPOTS]: (state = initialState): IRootState.HotspotsState => ({
      ...state,
      isLoading: false,
    }),

    [IHotspotsActionsType.HANDLE_EDIT_HOT_SPOTS]: (
      state = initialState,
      action,
    ): IRootState.HotspotsState => ({
      ...state,
      isEditing: true,
      selectedHotspot: action.payload as IHotspotModel,
    }),
  },
  initialState,
);

import * as React from 'react';
import moment from 'moment';
import Map from 'app/components/common/Map/Map';

import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  IFleetDetails,
  IVehicleTypeListModel,
  IFleetStateModel,
  VehicleStateType,
} from 'app/models';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  para: {
    marginBottom: 4,
  },
}));

export interface IProps {
  fleetDetails: IFleetDetails | null;
  isLoading: boolean;
  vehicleTypesList: IVehicleTypeListModel[];
  fleetStates: IFleetStateModel[];
}

export const FleetDetails: React.SFC<IProps> = ({
  fleetDetails,
  isLoading,
  vehicleTypesList,
  fleetStates,
}) => {
  const classes = useStyles();
  let waitingSparePartName: string | undefined = undefined;
  if (
    fleetDetails &&
    fleetDetails.stateId === VehicleStateType.waitingForParts &&
    fleetDetails.stateDescription &&
    fleetDetails.stateDescription.message
  ) {
    waitingSparePartName = fleetDetails.stateDescription.message.en;
  }
  return (
    <Card>
      <CardHeader title={'Details'} />
      <Divider />
      <CardContent>
        {!isLoading ? (
          fleetDetails ? (
            <Grid container spacing={3}>
              <Grid item lg={6} xs={12}>
                <Typography variant="body1" className={classes.para}>
                  State:{' '}
                  <strong style={{ textTransform: 'capitalize' }}>
                    {fleetStates.find((s) => s.id === fleetDetails.stateId)
                      ? fleetStates.find((s) => s.id === fleetDetails.stateId)!.translations.en
                      : 'unknown'}
                  </strong>
                  {waitingSparePartName ? ` (${waitingSparePartName})` : ''}
                </Typography>
                {fleetDetails.stateDescription && (
                  <Typography variant="body1" className={classes.para}>
                    Note: <strong>{fleetDetails.stateDescription.message}</strong>
                  </Typography>
                )}
                <Typography variant="body1" className={classes.para}>
                  Battery level: <strong>{fleetDetails.fuelLevel}%</strong>
                </Typography>
                <Typography variant="body1" className={classes.para}>
                  Type:{' '}
                  <strong>
                    {vehicleTypesList.find((v) => v.id === fleetDetails.vehicleTypeId)
                      ? vehicleTypesList.find((v) => v.id === fleetDetails.vehicleTypeId)!.name
                      : 'Vehicle Type Not Available'}
                  </strong>
                </Typography>
                {/* <Typography variant="body1" className={classes.para}>
                  Model: <strong>Segway ES4</strong>
                </Typography> */}
                <Typography variant="body1" className={classes.para}>
                  Mileage: <strong>{fleetDetails.mileage}</strong>
                </Typography>
                <Typography variant="body1" className={classes.para}>
                  Created: <strong>{moment(fleetDetails.createdAt).format('ll')}</strong>
                </Typography>
                <Typography variant="body1" className={classes.para}>
                  Last update:{' '}
                  <strong>
                    {moment(fleetDetails.updatedAt)
                      .startOf('hour')
                      .fromNow()}
                  </strong>
                </Typography>
                {fleetDetails.stateId === VehicleStateType.atRisk && (
                  <>
                    <Typography variant="body1" className={classes.para}>
                      Retrieval attempts: <strong>{fleetDetails.retrievalAttempts}</strong>
                    </Typography>
                    <Typography variant="body1" className={classes.para}>
                      Last attempt:{' '}
                      <strong>{moment(fleetDetails.lastRetrievalAttempt!).format('ll')}</strong>
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid container item lg={6} xs={12} justify="flex-end" direction="row">
                <div style={{ textAlign: 'left' }}>
                  <Typography variant="body1" gutterBottom style={{ marginRight: 10 }}>
                    Current Location:{' '}
                  </Typography>
                  <div className={'fleet-details-map'} style={{ height: 130, width: 280 }}>
                    <Map location={fleetDetails.location} isExpandable />
                  </div>
                </div>
              </Grid>
            </Grid>
          ) : (
            'No Data Found'
          )
        ) : (
          <div
            style={{
              minHeight: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <CircularProgress disableShrink size={40} thickness={4} />
            <Typography>Loading...</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IDashboardActionsType } from 'app/actions';
import {
  IDashboardModel,
  IPeriodModel,
  IDashboardFilterModel,
  IDashboardDataModel,
} from 'app/models';

const initialState: IDashboardModel = {
  dashboard: {
    vehiclesCount: [],
    servicesCountPerInterval: [],
    sparePartsUsed: [],
    vehiclesHeldInWarehouse: [],
    ordinaryServiceDuration: [],
  },
  isLoading: false,
  dashboardFilter: {
    period: IPeriodModel.LAST_WEEK,
    vehicleTypeId: 'all',
  },
  vehicleTypes: [],
  spareParts: [],
};

// tslint:disable-next-line:typedef
export const dashboardReducer: Reducer<IRootState.DashboardState, IDashboardModel> = handleActions<
  IRootState.DashboardState,
  IDashboardModel | IDashboardFilterModel | IDashboardDataModel
>(
  {
    [IDashboardActionsType.RECEIVE_DASHBOARD_VEHICLE_TYPES]: (
      state = initialState,
      action,
    ): IRootState.DashboardState => ({
      ...state,
      vehicleTypes: (action.payload as IDashboardModel).vehicleTypes,
    }),

    [IDashboardActionsType.RECEIVE_DASHBOARD_SPARE_PARTS]: (
      state = initialState,
      action,
    ): IRootState.DashboardState => ({
      ...state,
      spareParts: (action.payload as IDashboardModel).spareParts,
    }),

    [IDashboardActionsType.REQUEST_DASHBOARD_DATA]: (
      state = initialState,
    ): IRootState.DashboardState => ({
      ...state,
      isLoading: true,
    }),
    [IDashboardActionsType.RECEIVE_DASHBOARD_DATA]: (
      state = initialState,
      action,
    ): IRootState.DashboardState => ({
      ...state,
      isLoading: false,
      dashboard: action.payload as IDashboardDataModel,
    }),
    [IDashboardActionsType.FAILURE_DASHBOARD_DATA]: (
      state = initialState,
    ): IRootState.DashboardState => ({
      ...state,
      isLoading: false,
    }),
    [IDashboardActionsType.UPDATE_DASHBOARD_FILTER]: (
      state = initialState,
      action,
    ): IRootState.DashboardState => ({
      ...state,
      dashboardFilter: action.payload as IDashboardFilterModel,
    }),
  },
  initialState,
);

import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  ISupportedRoutes,
  IMethods,
  IResponseHandlerModel,
  IOnRoadTaskModel,
  IOnRoadTaskStatusModel,
} from 'app/models';
import { IRootState } from 'app/reducers/AppState';
import { push } from 'react-router-redux';
import { ApiHelper } from 'app/helpers/api-helper';

export enum IOnRoadTaskType {
  REQUEST_ONROAD_TASKS = 'REQUEST_ONROAD_TASKS',
  RECEIVE_ONROAD_TASKS = 'RECEIVE_ONROAD_TASKS',
  FAILURE_ONROAD_TASKS = 'FAILURE_ONROAD_TASKS',

  REQUEST_FLEET_STATUS = 'REQUEST_FLEET_STATUS',
  RECEIVE_FLEET_STATUS = 'RECEIVE_FLEET_STATUS',
  FAILURE_FLEET_STATUS = 'FAILURE_FLEET_STATUS',

  REQUEST_UPDATE_TASKS = 'REQUEST_UPDATE_TASKS',
  RECEIVE_UPDATE_TASKS = 'RECEIVE_UPDATE_TASKS',
  FAILURE_UPDATE_TASKS = 'FAILURE_UPDATE_TASKS',
}

// tslint:disable:typedef

export const requestOnroadTasks = createAction(IOnRoadTaskType.REQUEST_ONROAD_TASKS);
export const receiveOnroadTasks = createAction<IOnRoadTaskModel[]>(
  IOnRoadTaskType.RECEIVE_ONROAD_TASKS,
);
export const failureOnroadTasks = createAction(IOnRoadTaskType.FAILURE_ONROAD_TASKS);

export const requestFleetStatus = createAction(IOnRoadTaskType.REQUEST_FLEET_STATUS);
export const receiveFleetStatus = createAction<IOnRoadTaskStatusModel>(
  IOnRoadTaskType.RECEIVE_FLEET_STATUS,
);
export const failureFleetStatus = createAction(IOnRoadTaskType.FAILURE_FLEET_STATUS);

export const requestUpdateTasks = createAction(IOnRoadTaskType.REQUEST_UPDATE_TASKS);
export const receiveUpdateTasks = createAction(IOnRoadTaskType.RECEIVE_UPDATE_TASKS);
export const failureUpdateTasks = createAction(IOnRoadTaskType.FAILURE_UPDATE_TASKS);

export const fetchOnRoadTasks = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();
    if (selectedRegion && selectedRegion.key === 'all') {
      dispatch(push(ISupportedRoutes.DASHBOARD));
      return;
    }

    try {
      dispatch(requestOnroadTasks());

      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/onroad-tasks',
        IMethods.GET,
        true,
        { ...(selectedRegion && { regionId: selectedRegion.id }) },
      );

      if (response.isError) {
        dispatch(failureOnroadTasks());
        return;
      }

      dispatch(receiveOnroadTasks(response.data.data as IOnRoadTaskModel[]));
    } catch (e) {
      console.error(e);
      dispatch(failureOnroadTasks());
    }
  };
};

export const fetchFleetStatus = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();
    if (selectedRegion && selectedRegion.key === 'all') {
      dispatch(push(ISupportedRoutes.DASHBOARD));
      return;
    }

    try {
      dispatch(requestFleetStatus());

      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/onroad-tasks/status',
        IMethods.GET,
        true,
        { ...(selectedRegion && { regionId: selectedRegion.id }) },
      );
      const onRoadTaskStatus = response.data.data as IOnRoadTaskStatusModel;
      dispatch(receiveFleetStatus(onRoadTaskStatus));
    } catch (e) {
      dispatch(failureFleetStatus());
    }
  };
};

export const updateTasks = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();
    if (selectedRegion && selectedRegion.key === 'all') {
      return;
    }

    try {
      dispatch(requestUpdateTasks());

      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/onroad-tasks/generate',
        IMethods.GET,
        true,
        { ...(selectedRegion && { regionId: selectedRegion.id }) },
      );
      if(response.isError) {
        dispatch(failureUpdateTasks())
        return;
      }
      dispatch(receiveUpdateTasks());
      fetchOnRoadTasks()(dispatch, getState);
    } catch (e) {
      dispatch(failureUpdateTasks());
    }
  };
};

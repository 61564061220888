import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import {
  IHotspotModel,
  ISupportedRoutes,
  IMethods,
  INewHotspotModel,
  IResponseHandlerModel,
  ToastTypes,
  DeleteItemsFunction,
} from 'app/models';
import { IRootState } from 'app/reducers/AppState';
import { push } from 'react-router-redux';
import { ApiHelper } from 'app/helpers/api-helper';
import { showToaster } from './ToastActions';

const nativeProperties = ['priority', 'location', 'priorityPeriods', 'capacity'];

export enum IHotspotsActionsType {
  REQUEST_HOT_SPOTS = 'REQUEST_HOT_SPOTS',
  RECEIVE_HOT_SPOTS = 'RECEIVE_HOT_SPOTS',
  FAILURE_HOT_SPOTS = 'FAILURE_HOT_SPOTS',

  REQUEST_HOT_SPOT_DETAILS = 'REQUEST_HOT_SPOT_DETAILS',
  RECEIVE_HOT_SPOT_DETAILS = 'RECEIVE_HOT_SPOT_DETAILS',
  FAILURE_HOT_SPOT_DETAILS = 'FAILURE_HOT_SPOT_DETAILS',

  HANDLE_EDIT_HOT_SPOTS = 'HANDLE_EDIT_HOT_SPOTS',
}

// tslint:disable:typedef

export const requestHotspots = createAction(IHotspotsActionsType.REQUEST_HOT_SPOTS);
export const receiveHotspots = createAction<IHotspotModel[]>(
  IHotspotsActionsType.RECEIVE_HOT_SPOTS,
);
export const failureHotspots = createAction(IHotspotsActionsType.FAILURE_HOT_SPOTS);

export const requestHotspotDetails = createAction(IHotspotsActionsType.REQUEST_HOT_SPOT_DETAILS);
export const receiveHotspotDetails = createAction(IHotspotsActionsType.RECEIVE_HOT_SPOT_DETAILS);
export const failureHotspotDetails = createAction(IHotspotsActionsType.FAILURE_HOT_SPOT_DETAILS);

export const handleEditHotspots = createAction<IHotspotModel>(
  IHotspotsActionsType.HANDLE_EDIT_HOT_SPOTS,
);

export const fetchHotspots = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();

    if (selectedRegion && selectedRegion.key === 'all') {
      dispatch(push(ISupportedRoutes.DASHBOARD));
      return;
    }

    try {
      dispatch(requestHotspots());

      const response = await new ApiHelper().FetchFromPortal('/hotspots', IMethods.GET, true, {
        ...(selectedRegion && { regionId: selectedRegion.id }),
        includeWaiting: true,
        returnCurrentPriority: true,
      });

      if (response.isError) {
        dispatch(failureHotspots());
        return;
      }

      dispatch(receiveHotspots(response.data.data as IHotspotModel[]));
    } catch (e) {
      console.error(e);
      dispatch(failureHotspots());
    }
  };
};

export const saveHotspot = (hotspot: INewHotspotModel, file: any) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
      hotspots: { selectedHotspot, isEditing },
    } = getState();
    if (!selectedRegion) {
      showToaster({
        show: true,
        type: ToastTypes.ERROR,
        message: 'Region is undefined',
      })(dispatch);
      return;
    }
    if (selectedRegion.key === 'all') {
      alert(`Before saving, please choose a location on the sidebar navigation`);
      return;
    }
    try {
      let response: IResponseHandlerModel;
      const formData = new FormData();
      const data: any = {
        ...hotspot,
        ...(!isEditing && { regionId: selectedRegion.id }),
      };
      if (file) {
        formData.append('file', file);
      }
      Object.keys(data).forEach((item) => {
        if (data[item] === null || data[item] === undefined) return;
        if (nativeProperties.includes(item)) {
          data[item] = JSON.stringify(data[item]);
        }
        formData.append(`${item}`, data[item]);
      });
      if (selectedHotspot && isEditing) {
        response = await new ApiHelper().FetchFromPortal(
          '/hotspots/' + selectedHotspot.id,
          IMethods.PUT,
          true,
          undefined,
          formData,
          true,
        );
      } else {
        response = await new ApiHelper().FetchFromPortal(
          '/hotspots',
          IMethods.POST,
          true,
          undefined,
          formData,
          true,
        );
      }
      if (response.isError) {
        return;
      }
      dispatch(push(`/${selectedRegion.key}${ISupportedRoutes.HOT_SPOTS}`));
    } catch (e) {
      console.error(e);
    }
  };
};

export const editHotspot = (hotspot: IHotspotModel) => {
  return (dispatch: Dispatch) => {
    dispatch(handleEditHotspots(hotspot));
  };
};

export const fetchHotspotDetails = (id: string) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    try {
      const response = await new ApiHelper().FetchFromPortal('/hotspots/' + id, IMethods.GET, true);

      if (response.isError) {
        console.error(response.messages![0]);
        return;
      }

      const hotspot = response.data.data as IHotspotModel;
      dispatch(handleEditHotspots(hotspot));
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteHotspot = () => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
      hotspots: { selectedHotspot },
    } = getState();
    if (selectedHotspot && selectedRegion) {
      try {
        const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
          '/hotspots/' + selectedHotspot.id,
          IMethods.DELETE,
          true,
        );

        if (response.isError) {
          console.error(response.messages![0]);
          return;
        }
        dispatch(push(`/${selectedRegion.key}${ISupportedRoutes.HOT_SPOTS}`));
      } catch (e) {
        console.error(e);
      }
    }
  };
};

export const deleteHotspots: DeleteItemsFunction = (ids: string[]) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const {
      userDetails: { selectedRegion },
    } = getState();
    if (!selectedRegion) {
      showToaster({
        show: true,
        type: ToastTypes.ERROR,
        message: 'Region is undefined',
      })(dispatch);
      return;
    }
    try {
      // dispatch(requestDeleteRepairGuides({ removingGuidesIds: ids }));
      const response: IResponseHandlerModel = await new ApiHelper().FetchFromPortal(
        '/hotspots/',
        IMethods.DELETE,
        true,
        undefined,
        {
          repairGuides: ids,
        },
      );
      if (response.isError) {
        // dispatch(failureDeleteRepairGuides());
        console.error(response.messages![0]);
        return;
      }
      dispatch(push(`/${selectedRegion.key}${ISupportedRoutes.HOT_SPOTS}`));
      // dispatch(receiveDeleteRepairGuides());
    } catch (e) {
      // dispatch(failureDeleteRepairGuides());
    }
  };
};

import * as React from 'react';
import { IUserDetail, IRegion, IMaintainer, IToastStateModel, IToastModel } from 'app/models';
import { useStyles } from './AppLayoutStyle';
import { TopNavigation } from 'app/components/TopNavigation/TopNavigation';
import LeftNavigation from 'app/components/LeftNavigation/LeftNavigation';
import { ServeedoToast, Loader } from 'app/components/common';

export interface IProps {
  user: IUserDetail | null;
  fetchLocalStorage: () => void;
  fetchUserDetails: () => void;
  regions: IRegion[];
  onChangeRegion: (region: IRegion, maintainer?: IMaintainer) => void;
  selectedRegion: IRegion | null;
  isLoading: boolean;
  selectedMaintainer: IMaintainer | null;
  toasts: IToastStateModel;
  hideToast: (toast: IToastModel) => void;
}

const AppLayout: React.SFC<IProps> = ({
  user,
  fetchUserDetails,
  regions,
  onChangeRegion,
  selectedRegion,
  isLoading,
  selectedMaintainer,
  toasts,
  hideToast,
  children,
}) => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.useEffect(() => {
    if (!user) {
      fetchUserDetails();
    }
  }, [user, fetchUserDetails]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const token = localStorage.getItem('jwtToken');
  const loadingUser = token !== null && user === null;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <TopNavigation className={classes.topBar} handleDrawerToggle={handleDrawerToggle} />
        {!loadingUser ? (
          <React.Fragment>
            <LeftNavigation
              className={classes.navBar}
              user={user}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              regions={regions}
              onChangeRegion={onChangeRegion}
              selectedRegion={selectedRegion}
              isLoading={isLoading}
              selectedMaintainer={selectedMaintainer}
            />
            <main className={classes.content}>
              <div className={classes.contentChildren}>{children}</div>
            </main>
          </React.Fragment>
        ) : <Loader/>}
      </div>
      <ServeedoToast toasts={toasts} hideToast={hideToast} />
    </div>
  );
};

export default AppLayout;

import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IOnRoadTaskType } from 'app/actions';
import { IOnRoadTasksModel, IOnRoadTaskModel, IOnRoadTaskStatusModel } from 'app/models';

const initialState: IOnRoadTasksModel = {
  onRoadTasksList: [],
  isLoading: false,
  onRoadTaskStatus: {
    deployableVehicles: [],
    batteriesToReplace: [],
    availablePlaces: [],
  },
  isUpdatingTasks: false
};

// tslint:disable-next-line:typedef
export const onRoadTasksReducer: Reducer<
  IRootState.OnRoadTasksState,
  IOnRoadTasksModel
> = handleActions<
  IRootState.OnRoadTasksState,
  IOnRoadTasksModel | IOnRoadTaskModel[] | IOnRoadTaskStatusModel
>(
  {
    [IOnRoadTaskType.REQUEST_ONROAD_TASKS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isLoading: true,
    }),
    [IOnRoadTaskType.RECEIVE_ONROAD_TASKS]: (
      state = initialState,
      action,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isLoading: false,
      onRoadTasksList: action.payload as IOnRoadTaskModel[],
    }),
    [IOnRoadTaskType.FAILURE_ONROAD_TASKS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isLoading: false,
    }),

    [IOnRoadTaskType.REQUEST_FLEET_STATUS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
    }),
    [IOnRoadTaskType.RECEIVE_FLEET_STATUS]: (
      state = initialState,
      action,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      onRoadTaskStatus: action.payload as IOnRoadTaskStatusModel,
    }),
    [IOnRoadTaskType.FAILURE_FLEET_STATUS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
    }),

    [IOnRoadTaskType.REQUEST_UPDATE_TASKS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isUpdatingTasks: true
    }),
    [IOnRoadTaskType.RECEIVE_UPDATE_TASKS]: (
      state = initialState,
      action,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isUpdatingTasks: false
    }),
    [IOnRoadTaskType.FAILURE_FLEET_STATUS]: (
      state = initialState,
    ): IRootState.OnRoadTasksState => ({
      ...state,
      isUpdatingTasks: false
    }),
  },
  initialState,
);

import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IRootState } from 'app/reducers/AppState';
import { AddHotspot } from 'app/views';
import { IRegion, IVehicleTypeListModel, IHotspotModel, INewHotspotModel } from 'app/models';
import {
  fetchVehiclesTypesList,
  fetchHotspotDetails,
  saveHotspot,
  deleteHotspot,
} from 'app/actions';

interface StateFromProps {
  selectedRegion: IRegion | null;
  vehicleTypesList: IVehicleTypeListModel[];
  selectedHotspot: IHotspotModel | null;
  isEditing: boolean;
}

interface DispatchFromProps {
  fetchVehicleTypesList: () => void;
  fetchHotspotDetails: (id: string) => void;
  saveHotspot: (hotspot: INewHotspotModel, file: any) => void;
  deleteHotspot: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  selectedRegion: state.userDetails.selectedRegion,
  vehicleTypesList: state.vehicleTypes.vehicleTypesList,
  selectedHotspot: state.hotspots.selectedHotspot,
  isEditing: state.hotspots.isEditing,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  fetchVehicleTypesList: bindActionCreators(fetchVehiclesTypesList, dispatch),
  fetchHotspotDetails: bindActionCreators(fetchHotspotDetails, dispatch),
  saveHotspot: bindActionCreators(saveHotspot, dispatch),
  deleteHotspot: bindActionCreators(deleteHotspot, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddHotspot);

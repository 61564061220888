import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  value: {
    fontSize: 24,
    fontWeight: 500,
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    height: 37,
    width: 37,
  },
}));

export interface IProps {
  title: string;
  value: number | string;
  icon: any;
  className?: string;
  image: any;
}

export const DashboardWidget: React.SFC<IProps> = ({
  className,
  title,
  value,
  icon: Icon,
  image,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div>
        <Typography component="h4" gutterBottom variant="overline">
          {title}
        </Typography>
        <div className={classes.details}>
          <Typography variant="h4">
            <span className={classes.value}>{value}</span>
          </Typography>
        </div>
      </div>
      <Avatar className={classes.avatar} style={{ backgroundImage: image }}>
        <Icon style={{ height: '0.8em', width: '0.8em' }} />
      </Avatar>
    </Card>
  );
};

import palette from '../palette';

export default {
  root: {
    color: '#546E7A',
  },
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFFFFF',
  },
  containedPrimary: {
    color: palette.common.white,
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  label: {
    whiteSpace: 'nowrap',
  },
  textSecondary: {
    color: palette.error.main,
  },
};

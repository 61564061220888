import moment from 'moment';

export const makeDateRelative = (date: Date): string => {
  const currentDate: moment.Moment = moment();
  const days: number = currentDate.diff(date, 'days');
  if (Math.abs(moment().diff(date)) < 1000) {
    return 'just now';
  }

  if (days < 7) {
    return moment(date).fromNow();
  } else {
    return moment(date).format('Do MMM') + ' at ' + moment(date).format('h:mm a');
  }
};

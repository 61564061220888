import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';

import { EmployeesList } from 'app/components/EmployeesList/EmployeesList';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import { ServeedoButton } from 'app/components/common';
import {
  IEmployeesModel,
  INewEmployeeDetails,
  IEmployeeModel,
  IRegion,
  ISupportedRoutes,
  IUserDetail,
} from 'app/models';
import { AddEmployeeModal } from 'app/components/Modals/AddEmployeeModal/AddEmployeeModal';
import { RouteComponentProps } from 'react-router';
import { SectionDescription } from 'app/components/common/SectionDescription';

export interface IProps extends RouteComponentProps<any> {
  userDetails: IUserDetail | null;
  employeesData: IEmployeesModel;
  fetchAllEmployees: () => void;
  fetchAllActivitiesList: () => void;
  addNewEmployee: (employee: INewEmployeeDetails) => void;
  openEditEmployeeModal: (employee: IEmployeeModel) => void;
  closeEditEmployeeModal: () => void;
  editEmployee: (employee: INewEmployeeDetails) => void;
  deleteEmployee: (employeeId: string) => void;
  selectedRegion: IRegion | null;
}
export interface IState {
  showModal: boolean;
}

class Employees extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    const { selectedRegion } = this.props;
    if (selectedRegion && selectedRegion.key !== 'all') {
      this.props.fetchAllEmployees();
    }
    this.props.fetchAllActivitiesList();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      employeesData: { isAdding, isDeleting },
      selectedRegion,
      fetchAllEmployees,
      history,
    } = this.props;
    if (
      (prevProps.employeesData.isAdding !== isAdding && !isAdding) ||
      (prevProps.employeesData.isDeleting !== isDeleting && !isDeleting)
    ) {
      this.closeModal();
    }
    if (prevProps.selectedRegion !== selectedRegion && selectedRegion) {
      fetchAllEmployees();
      if (selectedRegion.key === 'all') return;
      const newUrl = '/' + selectedRegion.key + ISupportedRoutes.EMPLOYEES;
      history.push(newUrl);
    }
  }

  openUserModal = (employee?: IEmployeeModel) => {
    const { openEditEmployeeModal } = this.props;
    if (employee) {
      openEditEmployeeModal(employee);
    }
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    const {
      closeEditEmployeeModal,
      employeesData: { isEditing },
    } = this.props;
    if (isEditing) {
      closeEditEmployeeModal();
    }
    this.setState({
      showModal: false,
    });
  };

  renderButton = (): JSX.Element => {
    return (
      <ServeedoButton
        buttonText={'New Employee'}
        buttonIcon={<AddIcon />}
        onClick={() => this.openUserModal()}
      />
    );
  };

  render(): JSX.Element {
    const {
      userDetails,
      employeesData: {
        employeesList,
        isLoading,
        activitiesList,
        isAdding,
        selectedEmployee,
        isEditing,
        isDeleting,
      },
      addNewEmployee,
      editEmployee,
      deleteEmployee,
      selectedRegion,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div className={'container'}>
        <div>
          <WidgetHeader
            headerText={'Employees'}
            headerSmallText={selectedRegion ? selectedRegion.name.toUpperCase() : ''}
            rightPanel={this.renderButton()}
          />
          <SectionDescription>
            Manage here who can access{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.serveedo.android"
              rel="noopener noreferrer"
              target="_blank"
            >
              Serveedo's mobile app
            </a>
            .{' '}
            {userDetails && (
              <span>
                With the username and password, tell them to use{' '}
                <strong>{userDetails.company!.handle}</strong> as Company ID.
              </span>
            )}
          </SectionDescription>
          <EmployeesList
            employeesList={employeesList}
            isLoading={isLoading}
            openEditEmployeeModal={this.openUserModal}
            activitiesList={activitiesList}
          />
        </div>
        <AddEmployeeModal
          showModal={showModal}
          handleClose={this.closeModal}
          activitiesList={activitiesList}
          addNewEmployee={addNewEmployee}
          isAdding={isAdding}
          isEditing={isEditing}
          selectedEmployee={selectedEmployee}
          editEmployee={editEmployee}
          deleteEmployee={deleteEmployee}
          isDeletingEmployee={isDeleting}
        />
      </div>
    );
  }
}

export default Employees;

import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { OnRoadTasks } from 'app/views';
import { IRootState } from 'app/reducers/AppState';
import { IRegion, IOnRoadTasksModel, IVehicleTypeModel } from 'app/models';
import {
  fetchOnRoadTasks,
  fetchFleetStatus,
  fetchVehiclesTypesList,
  updateTasks,
} from 'app/actions';

interface StateFromProps {
  selectedRegion: IRegion | null;
  onRoadTasks: IOnRoadTasksModel;
  vehicleTypes: IVehicleTypeModel[];
}

interface DispatchFromProps {
  getOnRoadTask: () => void;
  fetchFleetStatus: () => void;
  fetchVehicleTypes: () => void;
  requestUpdateTasks: () => void;
}

const mapStateToProps = (state: IRootState): StateFromProps => ({
  selectedRegion: state.userDetails.selectedRegion,
  onRoadTasks: state.onRoadTasks,
  vehicleTypes: state.vehicleTypes.vehicleTypesList,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  getOnRoadTask: bindActionCreators(fetchOnRoadTasks, dispatch),
  fetchFleetStatus: bindActionCreators(fetchFleetStatus, dispatch),
  fetchVehicleTypes: bindActionCreators(fetchVehiclesTypesList, dispatch),
  requestUpdateTasks: bindActionCreators(updateTasks, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnRoadTasks);

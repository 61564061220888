import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  Theme,
} from '@material-ui/core';
import { ISparePartModel, ISparePartsUsedModel, IVehicleTypeModel } from 'app/models';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 475,
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  content: {
    flexGrow: 1,
    padding: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  avatar: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export interface IProps {
  vehicleTypes: IVehicleTypeModel[];
  className?: string;
  isLoading: boolean;
  spareParts: ISparePartModel[];
  items: ISparePartsUsedModel[];
  selectedVehicleId: string | null;
}

export const SparePartsUsage: React.SFC<IProps> = (props) => {
  const {
    className,
    isLoading,
    items,
    spareParts,
    vehicleTypes,
    selectedVehicleId,
    ...rest
  } = props;

  const classes = useStyles();

  const getVehicleTypeName = (id: string): string | undefined => {
    const type = vehicleTypes.find((t) => t.id === id);
    if (type) {
      return type.name;
    }
    return undefined;
  };

  const getPartLabel = (part: ISparePartsUsedModel): string => {
    for (let i = 0; i < spareParts.length; i++) {
      const localPart = spareParts[i];
      if (localPart.id === part.sparePartId) {
        if (!selectedVehicleId) {
          const vehicleName = getVehicleTypeName(localPart.vehicleTypeId);
          if (vehicleName) {
            return `${vehicleName} / ${localPart.translations.en}`;
          }
        }
        return localPart.translations.en;
      }
    }
    return part.sparePartId;
  };

  const compare = (a: ISparePartsUsedModel, b: ISparePartsUsedModel) => {
    if (a.count < b.count) {
      return 1;
    }
    if (a.count > b.count) {
      return -1;
    }
    return 0;
  };

  const list = items
    .filter((item) => {
      if (selectedVehicleId) {
        if (!spareParts) {
          return false;
        }
        const localPart = spareParts.find((part) => part.id === item.sparePartId);
        if (!localPart) {
          return false;
        }
        return localPart!.vehicleTypeId === selectedVehicleId;
      }
      return true;
    })
    .sort(compare);

  return (
    <Card {...rest} className={clsx(classes.root, { [classes.loading]: isLoading })}>
      {isLoading ? (
        <>
          <CircularProgress disableShrink size={30} thickness={3} />
          <br />
          <Typography>Loading...</Typography>
        </>
      ) : (
        <>
          <CardHeader title="Used spare parts" />
          <Divider />
          <CardContent className={classes.content}>
            <List disablePadding>
              {list.map((item, i) => (
                <ListItem divider={i < list.length - 1} key={i} className={classes.listItem}>
                  <ListItemText primary={getPartLabel(item)} />
                  <Typography variant="subtitle2">{item.count}</Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </>
      )}
    </Card>
  );
};

import * as React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
} from '@material-ui/core';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  NoData,
  ServeedoTableLoading,
  FilterMenuItem,
  ITableFilter,
  ServeedoStatus,
} from 'app/components/common';
import {
  IOnRoadTaskModel,
  OnroadTaskStatus,
  IHotspotModel,
  INewHotspotModel,
  OnroadTaskType,
} from 'app/models';

import { getSorting, stableSort, capitalizeFirstLetter } from 'app/helpers/common';
import { makeDateRelative } from 'app/helpers/time-helper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#607D8B',
    },
  }),
);

const headerCells: ITableHeaderCells[] = [
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'quantity', numeric: false, disablePadding: false, label: 'Vehicles' },
  { id: 'employeeId', numeric: true, disablePadding: false, label: 'Employee' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'comment', numeric: false, disablePadding: false, label: 'Comment' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Last Update' },
];

export interface IProps {
  onRoadTasksListList: IOnRoadTaskModel[];
  isLoading: boolean;
}

export const OnRoadTasksList: React.SFC<IProps> = ({ onRoadTasksListList, isLoading }) => {
  const classes = useStyles();

  const [order, setOrder] = React.useState<any>('desc');
  const [orderBy, setOrderBy] = React.useState('updatedAt');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selected, setSelected] = React.useState([]);
  const [status, setStatus] = React.useState<FilterMenuItem>({
    label: 'All',
    value: 'all',
  });

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = onRoadTasksListList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onroadTaskStatus = Object.keys(OnroadTaskStatus).map((key: any) => key);

  const tableFilter: ITableFilter[] = [
    {
      menuTitle: 'Status',
      items: [
        { label: 'All', value: 'all' },
        ...onroadTaskStatus.map((s) => ({ label: s.replace(/([A-Z])/g, ' $1').trim(), value: s })),
      ],
      onSelect: (value) => setStatus(value),
      selectedOption: status,
    },
  ];

  const getLocation = (onRoadTask: IOnRoadTaskModel): string => {
    let location = (onRoadTask.location as INewHotspotModel).location || onRoadTask.location;
    return location.address || location.lat + ', ' + location.long;
  };

  const filteredList = [...onRoadTasksListList].filter((s) =>
    status.value !== 'all'
      ? s.status
          .split('-')
          .join('')
          .toLowerCase()
          .includes((status.value as string).toLowerCase())
      : true,
  );

  let list = stableSort<IOnRoadTaskModel>(filteredList, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <Paper>
      <ServeedoTableToolbar title={'All Tasks'} tableFilter={tableFilter} />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <ServeedoTableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={onRoadTasksListList.length}
            headerCells={headerCells}
          />
          <TableBody>
            {isLoading ? (
              <ServeedoTableLoading numberOfColumns={6} />
            ) : (
              list.map((onRoadTask: IOnRoadTaskModel, index: number) => {
                let typeStr = capitalizeFirstLetter(onRoadTask.type);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    style={{
                      cursor: 'default',
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <span>{typeStr}</span>
                      {onRoadTask.type === OnroadTaskType.Drop &&
                        onRoadTask.location &&
                        (onRoadTask.location as IHotspotModel).name && (
                          <span>
                            {' '}
                            to <span>{(onRoadTask.location as IHotspotModel).name}</span>
                          </span>
                        )}
                      <br />
                      <span className={classes.label}>{getLocation(onRoadTask)}</span>
                    </TableCell>
                    <TableCell>
                      {onRoadTask.status === OnroadTaskStatus.PartiallyCancelled
                        ? `${onRoadTask.quantity} / ${onRoadTask.initialQuantity || '?'}`
                        : onRoadTask.initialQuantity || onRoadTask.quantity}
                    </TableCell>
                    <TableCell>
                      {onRoadTask.employee ? onRoadTask.employee.fullname : onRoadTask.employeeId}
                    </TableCell>
                    <TableCell>
                      <ServeedoStatus type={onRoadTask.status} />
                    </TableCell>
                    <TableCell style={{ width: '30%' }}>{onRoadTask.comment}</TableCell>
                    <TableCell>{makeDateRelative(onRoadTask.updatedAt)}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      {!isLoading && !filteredList.length && (
        <>
          <NoData />
          <Divider />
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={filteredList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

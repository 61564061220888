import * as React from 'react';

import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import { IVehicleTypeListModel, INewHotspotModel, IPriorityPeriod } from 'app/models';
import { useStyles } from '../AddNewRepairGuide/MetaDataForm/MetaDataFormStyles';
import Map from 'app/components/common/Map/Map';
import { PriorityPeriod } from '../PriorityPeriod/PriorityPeriod';
import { ImageUpload } from '../common/ImageUpload/ImageUpload';

export interface IProps {
  vehicleTypesList: IVehicleTypeListModel[];
  handleChange: (hotspot: INewHotspotModel) => void;
  hotspot: INewHotspotModel;
  nameError?: string;
  vehicleTypeError?: string;
  priorityPeriods: IPriorityPeriod[];
  handleChangeFile: (file: any) => void;
}

export const AddHotspotForm: React.SFC<IProps> = ({
  vehicleTypesList,
  hotspot,
  handleChange,
  nameError,
  vehicleTypeError,
  priorityPeriods,
  handleChangeFile,
}) => {
  const classes = useStyles();

  const { vehicleTypeId, priority, capacity, location, name, description } = hotspot;
  return (
    <Paper style={{ marginTop: 24 }}>
      <form className={classes.container} autoComplete="off" onSubmit={() => console.log('okkk')}>
        <Grid container spacing={1}>
          <Grid container item xs={12} md={6}>
            <TextField
              id="name-required"
              label="Name"
              value={name}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              onChange={(e) => {
                handleChange({ ...hotspot, name: e.target.value });
              }}
              error={nameError !== undefined}
              helperText={nameError}
            />
          </Grid>
          <Grid container item xs={12} md={6}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel
                htmlFor="outlined-age-native-simple"
                className={classes.label}
                error={vehicleTypeError !== undefined}
              >
                Vehicle Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                inputProps={{
                  name: 'vehicelType',
                  id: 'outlined-age-native-simple',
                }}
                className={classes.select}
                onChange={(e) => {
                  let vType = [...vehicleTypesList].find((v) => v.id === e.target.value);
                  if (vType) {
                    handleChange({ ...hotspot, vehicleTypeId: vType.id });
                  }
                }}
                value={vehicleTypeId ? vehicleTypeId : ''}
                defaultValue={vehicleTypeId ? vehicleTypeId : ''}
                error={vehicleTypeError !== undefined}
              >
                {[...vehicleTypesList].map((v: any, index: number) => (
                  <MenuItem key={index} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
              {vehicleTypeError && (
                <FormHelperText style={{ color: '#e53935' }}>{vehicleTypeError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={6}>
            <TextField
              key={'location'}
              id="location-required"
              label="Location"
              value={`${location.lat}, ${location.long}`}
              className={classes.textField}
              margin="normal"
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid container item xs={12} md={3}>
            <TextField
              key={'capacity'}
              type={'number'}
              id="capacity-required"
              label="Capacity"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={capacity}
              onChange={(e) => {
                handleChange({ ...hotspot, capacity: Number(e.target.value) });
              }}
            />
          </Grid>
          <Grid container item xs={12} md={3}>
            <TextField
              key={'priority'}
              type={'number'}
              id="priority-required"
              label="Priority"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={priority}
              onChange={(e) => {
                handleChange({ ...hotspot, priority: Number(e.target.value) });
              }}
            />
          </Grid>
          <Grid container item>
            <PriorityPeriod
              priorityPeriods={priorityPeriods}
              addOrUpdatePriorityPeriod={(priorityPeriods) => {
                handleChange({
                  ...hotspot,
                  priorityPeriods,
                });
              }}
            />
          </Grid>
          <Grid container item className={'hotspot-map'} style={{ height: 430, width: '100%' }}>
            <Map
              location={location}
              changeLocation={(location) => handleChange({ ...hotspot, location })}
              isMarkerDraggable
            />
          </Grid>
          <Grid container item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={hotspot.needsProof}
                  onChange={(e) => handleChange({ ...hotspot, needsProof: e.target.checked })}
                  name="needsProof"
                />
              }
              label="Require photo proof"
              labelPlacement="end"
            />
          </Grid>
          <Grid container item xs={12}>
            <TextField
              id="outlined-multiline-flexible"
              label="Instructions (optional)"
              className={classes.textField}
              style={{ minHeight: 150 }}
              multiline
              variant="outlined"
              placeholder="e.g. park them in front of shop X."
              value={description}
              onChange={(e) => {
                handleChange({ ...hotspot, description: e.target.value });
              }}
            />
          </Grid>
          <Grid container item xs={12}>
            <div>
              <Typography variant="body1" display="block" gutterBottom>
                Photo reference (optional)
              </Typography>
              <ImageUpload
                name={'hotspotPhoto'}
                onChange={handleChangeFile}
                onRemove={() => {
                  handleChangeFile(null);
                  handleChange({ ...hotspot, photoUrl: null });
                }}
                defaultImage={hotspot.photoUrl}
              />
            </div>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

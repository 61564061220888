import { IFleetStateModel } from './FleetModel';
import { IVehicleTypeModel, ITranslationModel } from './VehicleTypesModel';

export interface ISettingsModel {
  wunderApiKey: string;
  wunderApiUrl: string;
  isSavingIntegrationInfo: boolean;
  localVehicleTypes: IVehicleTypeModel[];
  remoteVehicleTypes: IRemoteMapping121[];
  localVehicleStates: IFleetStateModel[];
  remoteVehicleStates: IRemoteMapping121[];
  remoteRegions: IRemoteMapping121[];
  integrationHistory: IIntegrationResult[];
  autoLockingRules: IAutoLockingRule[];
  isProcessingModal: boolean;
  accountError?: string;
  modalError?: string;
}

export interface IRemoteMapping121 {
  id: number;
  localId: string | number;
  remoteId: number;
  name?: string;
}

export interface IIntegrationResult {
  status: string;
  description: string;
  completedAt: string;
  dateStr?: string;
}

export interface IAutoLockingRule {
  id: number;
  translations: ITranslationModel;
  locked: boolean;
}

export type SaveIntegrationFunction = (key: string, url: string) => void;

export type ChangeAutoLockingRuleFunction = (stateId: number, locked: boolean) => void;

export enum IMappingType {
  NONE = 'None',
  STATE = 'State',
  REGION = 'Location',
  VEHICLE = 'Vehicle',
}

export type SaveIntegrationMappingFunction = (
  type: IMappingType,
  localId: string | number,
  remoteId: string | number,
  mapId: number | null,
) => void;

export type DeleteIntegrationMappingFunction = (type: IMappingType, mapId: number) => void;

import React from 'react';
import clsx from 'clsx';
import { Drawer, Avatar, Typography, Hidden } from '@material-ui/core';

import { Navigation } from './Navigation/Navigation';
import navigationAdminConfig from '../../constants/NavigationConfig/navigationAdminConfig';
import navigationOwnerConfig from '../../constants/NavigationConfig/navigationOwnerConfig';
import navigationMaintainerConfig from '../../constants/NavigationConfig/navigationMaintainerConfig';
import { IUserDetail, IUserRole, IRegion, IMaintainer } from 'app/models/UserDetails';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './LeftNavigationStyles';
import { NavigationLoading } from './NavigationLoading/NavigationLoading';
import { ISupportedRoutes, IConfigRoutesModel, CompanyRoleType } from 'app/models';
import useRouter from 'app/helpers/use-router';

export interface IProps {
  className: string;
  user: IUserDetail | null;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  regions: IRegion[];
  onChangeRegion: (region: IRegion, maintainer?: IMaintainer) => void;
  selectedRegion: IRegion | null;
  selectedMaintainer: IMaintainer | null;
  isLoading: boolean;
}

const LeftNavigation: React.SFC<IProps> = ({
  user,
  mobileOpen,
  handleDrawerToggle,
  className,
  regions,
  onChangeRegion,
  selectedRegion,
  isLoading,
  selectedMaintainer,
  ...rest
}) => {
  const router = useRouter();
  const [navigation, setNavigation] = React.useState<IConfigRoutesModel[]>([]);

  React.useEffect(() => {
    let newNavigation: IConfigRoutesModel[] = [];
    const userDetails = user;

    if (userDetails) {
      switch (userDetails.role) {
        case IUserRole.Admin:
          newNavigation = [...navigationAdminConfig];
          break;
        case IUserRole.FleetOwner:
          newNavigation = navigationOwnerConfig.map((n) => ({
            title: n.title,
            pages: selectedRegion
              ? selectedRegion.key !== 'all'
                ? n.pages
                : n.pages.filter(
                    (p) =>
                      !p.href.includes(ISupportedRoutes.PARTS_STOCK) &&
                      !p.href.includes(ISupportedRoutes.HOT_SPOTS) &&
                      !p.href.includes(ISupportedRoutes.EMPLOYEES) &&
                      !p.href.includes(ISupportedRoutes.TASKS),
                  )
              : n.pages,
          }));
          break;
        case IUserRole.Maintainer:
          newNavigation = [...navigationMaintainerConfig].map((n) => ({
            title: n.title,
            pages: selectedRegion
              ? selectedRegion.key !== 'all'
                ? n.pages
                : n.pages.filter(
                    (p) =>
                      !p.href.includes(ISupportedRoutes.PARTS_STOCK) &&
                      !p.href.includes(ISupportedRoutes.EMPLOYEES) &&
                      !p.href.includes(ISupportedRoutes.TASKS),
                  )
              : n.pages,
          }));
          break;
      }
      if (userDetails.companyRole !== CompanyRoleType.Administrator) {
        // filter out admin pages
        newNavigation.forEach((n) => {
          n.pages = n.pages.filter(
            (p) => !(p.onlyCompanyAdmin !== undefined && p.onlyCompanyAdmin === true),
          );
        });
      }
      setNavigation(newNavigation);
    } else {
      localStorage.clear();
      router.history.push(ISupportedRoutes.LOGIN);
    }
  }, [user, selectedRegion, router]);

  const classes = useStyles();
  const theme = useTheme();

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        {isLoading ? (
          <Skeleton variant="circle" width={60} height={60} />
        ) : (
          <Avatar
            src={user && user.company ? user.company.logoUrl : undefined}
            className={classes.avatar}
          />
        )}
        <Typography className={classes.name} variant="h5">
          {isLoading ? (
            <Skeleton
              variant="text"
              width={`${Math.floor(Math.random() * 20) + 100}px`}
              height={22}
              style={{ margin: 'auto' }}
            />
          ) : user && user.company ? (
            user.company.name
          ) : (
            'Company'
          )}
        </Typography>
      </div>
      <nav className={classes.navigation}>
        {isLoading ? (
          <>
            <NavigationLoading />
            <NavigationLoading />
          </>
        ) : (
          [...navigation].map((list: IConfigRoutesModel) => {
            if (selectedRegion) {
              if (list.title === 'Location') {
                list.pages.forEach((page) => {
                  if (
                    page.href.includes(ISupportedRoutes.PARTS_STOCK) ||
                    page.href.includes(ISupportedRoutes.EMPLOYEES) ||
                    page.href.includes(ISupportedRoutes.FLEET) ||
                    page.href.includes(ISupportedRoutes.VIEW_FLEET) ||
                    page.href.includes(ISupportedRoutes.DASHBOARD) ||
                    page.href.includes(ISupportedRoutes.HOT_SPOTS) ||
                    page.href.includes(ISupportedRoutes.NEW_HOT_SPOTS) ||
                    page.href.includes(ISupportedRoutes.TASKS)
                  ) {
                    const newPage = page.href.split('/');
                    const newUrl =
                      `${selectedRegion.key !== 'all' ? '/' + selectedRegion.key : ''}` +
                      '/' +
                      newPage[newPage.length - 1];

                    page.href = newUrl as ISupportedRoutes;
                  }
                });
              }
            }
            return (
              <Navigation
                component="div"
                key={list.title}
                pages={list.pages}
                title={list.title}
                regions={regions}
                onChangeRegion={onChangeRegion}
                selectedRegion={selectedRegion}
                maintainers={user && user.company ? user.company.maintainers : null}
                selectedMaintainer={selectedMaintainer}
              />
            );
          })
        )}
      </nav>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default LeftNavigation;

import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
} from '@material-ui/core';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  NoData,
  ServeedoTableLoading,
} from 'app/components/common';
import { IEmployeeModel, IActivityModel } from 'app/models';
import { getSorting, stableSort } from 'app/helpers/common';
import { makeDateRelative } from 'app/helpers/time-helper';

const headerCells: ITableHeaderCells[] = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'fullname', numeric: false, disablePadding: false, label: 'Full Name' },
  { id: 'username', numeric: true, disablePadding: false, label: 'Username' },
  { id: 'allowedActivitiesIds', numeric: true, disablePadding: false, label: 'Activities' },
  { id: 'hasVanFull', numeric: true, disablePadding: false, label: 'Has Van Full' },
  { id: 'createdAt', numeric: true, disablePadding: false, label: 'Added' },
];

export interface IProps {
  employeesList: IEmployeeModel[];
  isLoading: boolean;
  openEditEmployeeModal: (employee: IEmployeeModel) => void;
  activitiesList: IActivityModel[];
}

export const EmployeesList: React.SFC<IProps> = ({
  employeesList,
  isLoading,
  openEditEmployeeModal,
  activitiesList,
}) => {
  const [order, setOrder] = React.useState<any>('asc');
  const [orderBy, setOrderBy] = React.useState('fullname');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchKey, setSearchKey] = React.useState<string>('');

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = employeesList.map((n) => n.fullname);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name: any) => false;

  const list = employeesList.filter((e) => {
    const regex = new RegExp(searchKey, 'i');
    return regex.test(e.id) || regex.test(e.fullname) || regex.test(e.username);
  });

  const pageList = stableSort<IEmployeeModel>(list, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <Paper>
      <ServeedoTableToolbar
        title={'All Employees'}
        tableSearch
        searchFind={(value) => setSearchKey(value)}
      />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <ServeedoTableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={employeesList.length}
            headerCells={headerCells}
            selecting={false}
          />
          <TableBody>
            {isLoading ? (
              <ServeedoTableLoading numberOfColumns={6} />
            ) : (
              pageList.map((employee: IEmployeeModel, index: number) => {
                const isItemSelected = isSelected(employee.username);

                return (
                  <TableRow
                    hover
                    onClick={() => openEditEmployeeModal(employee)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                  >
                    <TableCell component="th" scope="row">
                      {employee.id}
                    </TableCell>
                    <TableCell>{employee.fullname}</TableCell>
                    <TableCell>{employee.username}</TableCell>
                    <TableCell style={{ textTransform: 'capitalize' }}>
                      {employee.allowedActivities
                        .map((a) => {
                          if (activitiesList.find((i: IActivityModel) => i.id === a.id)) {
                            return activitiesList.find((i: IActivityModel) => i.id === a.id)!
                              .translations['en'];
                          }
                          return a;
                        })
                        .join(', ')}
                    </TableCell>
                    <TableCell>{employee.hasVanFull ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{makeDateRelative(employee.createdAt)}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      {!isLoading && !list.length && (
        <>
          <NoData />
          <Divider />
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

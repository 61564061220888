import * as React from 'react';

import { Card, CardHeader, Divider, CardContent } from '@material-ui/core';

import { useStyles } from './HistoryStyles';
import { NoData } from 'app/components/common';
import { IIntegrationResult } from 'app/models';
import { IntegrationTableRow } from 'app/views/Settings/Integration/IntegrationTableRow';

export interface IProps {
  items: IIntegrationResult[];
}

export const History: React.SFC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Card style={{ marginTop: 24 }}>
      <CardHeader title={'History'} />
      <Divider />
      <CardContent className={classes.cardContent}>
        {props.items.length > 0 ? (
          props.items.map((item, index) => {
            return (
              <IntegrationTableRow title={item.description} value={item.dateStr!} key={index} />
            );
          })
        ) : (
          <NoData message={'No History found'} />
        )}
      </CardContent>
    </Card>
  );
};

import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress,
  Typography,
  Theme,
} from '@material-ui/core';

import { LineChart } from './LineChart/LineChart';
import { IServicesCountPerIntervalModel } from 'app/models';
import moment from 'moment';
import { getTotalVehicleTypesCount } from '../../models/DashboardModel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  chart: {
    height: '100%',
  },
}));

export interface IProps {
  className?: string;
  data: IServicesCountPerIntervalModel[];
  isLoading: boolean;
  selectedVehicleId: string | null;
}

export const NumberOfServices: React.SFC<IProps> = (props) => {
  const { className, data, selectedVehicleId, isLoading, ...rest } = props;

  const classes = useStyles();

  const lineData = data.map((item) => {
    if (selectedVehicleId) {
      const unit = item.data.find((u) => u.vehicleTypeId === selectedVehicleId);
      return unit ? unit.count : 0;
    }
    return getTotalVehicleTypesCount(item.data);
  });

  const labels = data.map((item) => {
    return moment(item.interval).format('DD/MM');
  });

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Number Of Services" />
      <Divider />
      <CardContent className={classes.content}>
        <div className={clsx(classes.inner, { [classes.loading]: isLoading })}>
          {isLoading ? (
            <>
              <CircularProgress disableShrink size={40} thickness={3} />
              <br />
              <Typography>Loading...</Typography>
            </>
          ) : (
            <LineChart className={classes.chart} data={lineData} labels={labels} />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

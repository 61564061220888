import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
// import CommuteOutlinedIcon from '@material-ui/icons/CommuteOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import InputIcon from '@material-ui/icons/Input';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { ISupportedRoutes, IConfigRoutesModel } from 'app/models';

const nav: IConfigRoutesModel[] = [
  {
    title: 'Location',
    pages: [
      {
        title: 'Overview',
        href: ISupportedRoutes.DASHBOARD,
        icon: HomeOutlinedIcon,
      },
      // {
      //   title: 'Vans',
      //   href: ISupportedRoutes.VANS,
      //   icon: CommuteOutlinedIcon,
      // },
      {
        title: 'Employees',
        href: ISupportedRoutes.EMPLOYEES,
        icon: PeopleAltOutlinedIcon,
      },
      {
        title: 'Fleet',
        href: ISupportedRoutes.FLEET,
        icon: DriveEtaOutlinedIcon,
      },
      {
        title: 'Parts Stock',
        href: ISupportedRoutes.PARTS_STOCK,
        icon: InboxOutlinedIcon,
      },
      {
        title: 'Onroad Tasks',
        href: ISupportedRoutes.TASKS,
        icon: CheckCircleOutlineIcon,
      },
    ],
  },
  {
    title: 'Account',
    pages: [
      {
        title: 'Users',
        href: ISupportedRoutes.USERS,
        icon: PeopleOutlineOutlinedIcon,
        onlyCompanyAdmin: true,
      },
      {
        title: 'Sign Out',
        href: ISupportedRoutes.LOGIN,
        icon: InputIcon,
      },
    ],
  },
];

export default nav;

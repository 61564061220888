import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { OnroadTaskStatus } from 'app/models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    statusText: {
      border: '1px solid',
      borderRadius: 4,
      margin: 0,
      padding: '0 6px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: 0.2,
      textTransform: 'uppercase',
    },
  }),
);

interface IProps {
  type: OnroadTaskStatus;
}

const renderStatusColor = (color: OnroadTaskStatus) => {
  switch (color) {
    case OnroadTaskStatus.InProgress:
      return {
        color: '#1E88E5',
        borderColor: '#1E88E5',
      };
    case OnroadTaskStatus.NotStarted:
      return {
        color: '#37474F',
        borderColor: '#37474F',
      };
    case OnroadTaskStatus.Completed:
      return {
        color: '#4CAF50',
        borderColor: '#4CAF50',
      };
    case OnroadTaskStatus.Cancelled:
    case OnroadTaskStatus.PartiallyCancelled:
      return {
        color: '#B71C1C',
        borderColor: '#B71C1C',
      };
    default:
      return {
        color: '#9E9E9E',
        borderColor: '#9E9E9E',
      };
  }
};

export const ServeedoStatus: React.SFC<IProps> = ({ type }) => {
  const classes = useStyles();

  let label = type.replace('-', ' ');
  if (type === OnroadTaskStatus.PartiallyCancelled) {
    label = 'partial';
  }

  return (
    <div className={classes.root}>
      <Typography
        variant="subtitle2"
        gutterBottom
        className={classes.statusText}
        style={renderStatusColor(type)}
      >
        {label}
      </Typography>
    </div>
  );
};
